import React, { useState } from "react";
import SEO from "../components/global/Seo";
import Layout from "../components/layout/Layout";
import Lightbox from "react-image-lightbox";

import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import Masonry from "react-masonry-css";

export default () => {
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const query = useStaticQuery(graphql`
    query {
      bannerImg2: file(relativePath: { eq: "coffee-machine.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      gallery: allFile(
        filter: { relativeDirectory: { eq: "galeria_serwis" } }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      proces: allFile(
        sort: { fields: [base] }
        filter: { relativeDirectory: { eq: "proces_serwis" } }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      klienci: allFile(
        sort: { fields: [base] }
        filter: { relativeDirectory: { eq: "klienci" } }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 200) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `);

  return (
    <Layout pageTitle="Serwis">
      <SEO title="Serwis" />
      <div className="row align-items-center section-padding-xs paragraph-content">
        <div className="col-md-6">
          <div className="text-box-padding-xs-right">
            <h2>Naprawimy Twój ekspres</h2>
            <strong>
              Nie wszystko co zepsute trzeba wymienić na nowe. Czasami wystarczy
              szybka ekspertyza fachowca i Twój ekspres znowu zacznie
              robić pyszną kawę.
            </strong>
            <p>
              Oferujemy profesjonalny serwis ekspresów począwszy od
              odkamieniania drobnych automatów jak i kompleksowych renowacji.
              Potrafimy tchnąć nowe życie w sprzęt skazany już na straty!
            </p>
            <p>
              Każdy przypadek traktujemy indywidualnie zarówno w kwestii wyceny
              jak i terminu realizacji, jesteśmy elastyczni i lubimy wyzwania. W
              razie konieczności odbierzemy i odwieziemy wasze „cuda”.
            </p>
          </div>
        </div>
        <div className="col-md-6">
          <Masonry
            breakpointCols={3}
            className="masonry-grid"
            columnClassName="masonry-grid_column"
          >
            {query.gallery.edges.map((edge, i) => {
              return (
                <div
                  role="layout"
                  key={i}
                  className="gallery-item"
                  onClick={() => {
                    setIsOpen("gallery");
                    setPhotoIndex(i);
                  }}
                >
                  <Img
                    fluid={edge.node.childImageSharp.fluid}
                    alt="Serwis galeria"
                  />
                </div>
              );
            })}
          </Masonry>
        </div>
      </div>
      <div className="row paragraph-content producer-info">
        <div className="col-md-3">
          <h3 className="producer-title">Proces renowacji ekspresu</h3>
        </div>
        <div className="col-md-8 offset-md-1">
          <Masonry
            breakpointCols={3}
            className="masonry-grid"
            columnClassName="masonry-grid_column"
          >
            {query.proces.edges.map((edge, i) => {
              return (
                <div
                  role="layout"
                  key={i}
                  className="gallery-item"
                  onClick={() => {
                    setIsOpen("proces");
                    setPhotoIndex(i);
                  }}
                >
                  <Img
                    fluid={edge.node.childImageSharp.fluid}
                    alt="Proces renowacji"
                  />
                </div>
              );
            })}
          </Masonry>
        </div>
      </div>
      <div className="row align-items-center justify-content-end section-padding-xs image-text-box paragraph-content">
        <div className="box-image">
          <Img fluid={query.bannerImg2.childImageSharp.fluid} alt="Banner" />
        </div>
        <div className="col-md-8 col-lg-6">
          <div className="box-text">
            <h3>Rekomendują nas między innymi</h3>

            <Masonry
              breakpointCols={4}
              className="masonry-grid mt-4"
              columnClassName="masonry-grid_column"
            >
              {query.klienci.edges.map((edge, i) => {
                return (
                  <div key={i}>
                    <Img
                      key={i}
                      fluid={edge.node.childImageSharp.fluid}
                      alt="Klient logo"
                    />
                  </div>
                );
              })}
            </Masonry>
          </div>
        </div>
      </div>
      {!!isOpen && (
        <Lightbox
          mainSrc={
            query[isOpen].edges[photoIndex].node.childImageSharp.fluid.src
          }
          nextSrc={
            query[isOpen].edges[(photoIndex + 1) % query[isOpen].edges.length]
              .node.childImageSharp.fluid.src
          }
          prevSrc={
            query[isOpen].edges[
              (photoIndex + query[isOpen].edges.length - 1) %
                query[isOpen].edges.length
            ].node.childImageSharp.fluid.src
          }
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (photoIndex + query[isOpen].edges.length - 1) %
                query[isOpen].edges.length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % query[isOpen].edges.length)
          }
        />
      )}
    </Layout>
  );
};
